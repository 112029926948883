export class ApiConfig {
  static readonly urlConstant = {
    profile: '/profile',
    metaData: '/utils/metadata',
    user: '/user',
    userProfile: '/user/profile',
    jobs: '/jobs',
    bid: '/bid',
    support: '/support',
    notification: '/notification',
  };
}
