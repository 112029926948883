import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TokenService } from '../token.service';
import { CognitoService } from '../cognito.service';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private cognitoService: CognitoService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.cognitoService.currentSession()).pipe(
      switchMap((res) => {
        // const token = localStorage.getItem(Keys.ID_TOKEN);
        const token = res.tokens?.idToken?.toString();
        let authString = 'Bearer ';
        if (token) authString = authString + token;
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authString,
        });
        const cloneRequest = request.clone({
          headers,
        });
        return next.handle(cloneRequest);
      }),
    );
  }
}
