import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  private readonly tourKey = 'tourCompleted';

  constructor(private shepherdService: ShepherdService, private router: Router) {}

  public startTour(): void {
    this.shepherdService.defaultStepOptions = {
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: true,
    };

    // Get the current route
    const currentRoute = this.router.url;
    // Determine which tour steps to show based on the route
    const tourSteps = this.getTourSteps(currentRoute);
    this.shepherdService.addSteps(tourSteps);

    this.shepherdService.tourObject.on('cancel', () => this.setTourCompleted());
    // Listen for when the tour is completed
    this.shepherdService.tourObject.on('complete', () => this.setTourCompleted());
    this.shepherdService.start();
  }

  private getTourSteps(route: string): any {
    switch (route) {
      case '/home':
        return [
          {
            id: 'step1',
            title: '<strong>Welcome to Builtdesign!</strong>',
            text: 'Using this button you can fill the  form describing the requirements of yours so that we can connect you to some architect.',
            attachTo: {
              element: '.step1',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Skip',
                secondary: 'true',
                action: () => this.shepherdService.complete(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step2',
            title: '<strong>You can estimate the cost for your Project</strong>',
            text: 'This calculator offers a rough estimate for your project based on the area in square feet.',
            attachTo: {
              element: '.step2',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step3',
            title: '<strong>View architect profiles</strong>',
            text: 'You can view various top architect profiles on our platform.',
            attachTo: {
              element: '.step3',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step4',
            title: '<strong>Provide your valuable Feedback</strong>',
            text: 'Using this section you can provide your valuable feedback on where we can improve.',
            attachTo: {
              element: '.step4',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step5',
            title: '<strong>View Notifications</strong>',
            text: 'You can view various notifications here about various activities of yours.',
            attachTo: {
              element: '.step5',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            title: '<strong>Customer Support</strong>',
            text: 'In case of any support you need we are here to help you. Please let us know it and our team member will contact you.',
            attachTo: {
              element: '.step6',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Finish',
                action: () => this.shepherdService.complete(),
              },
            ],
          },
        ];

      case '/page2':
        return [
          {
            id: 'step1',
            text: 'Welcome to Page 2! Here’s a quick guide.',
            attachTo: {
              element: '.page2-step1',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step2',
            text: 'More details about Page 2.',
            attachTo: {
              element: '.page2-step2',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Finish',
                action: () => this.shepherdService.complete(),
              },
            ],
          },
        ];

      // Add more cases as needed for different pages/routes

      default:
        return []; // No tour for unknown routes
    }
  }

  private setTourCompleted(): void {
    localStorage.setItem(this.tourKey, 'true');
  }

  public isTourCompleted(): boolean {
    return localStorage.getItem(this.tourKey) === 'true';
  }
}
