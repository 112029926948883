export const environment = {
  development: true,
  serverUrl: 'https://api.builtdesign.co.in/api/v1',
  architectUrl: 'https://architect.builtdesign.co.in',
  razorpayKey: 'rzp_test_FMQFVdxrKEEpHi',
  redirectSignIn: ['http://localhost:4200', 'https://client.builtdesign.co.in'],
  redirectSignOut: ['http://localhost:4200/login', 'https://client.builtdesign.co.in/login'],
  awsConfig: {
    region: 'ap-south-1',
    userBucket: 'project-content.builtdesign.in',
    identityPoolId: 'ap-south-1:b50ed26c-d49f-4078-be05-e05503da1fe6',
    userPoolId: 'ap-south-1_YWo8dAteJ',
    userPoolWebClientId: '265rtbg06oafi1fqj56as844mj',
    domain: 'client-dev.auth.ap-south-1.amazoncognito.com',
    analyticsDisabled: false,
  },
  recaptcha: {
    siteKey: '6LdAdy8qAAAAAMK8jq3a7ybx6y1tzG0XZFditz8Q',
    secretKey: '6LdAdy8qAAAAANiZa_ZIqHDlMNiYnLuo5Strp9jk',
  },
  env: 'dev',
  sentryDsn: 'https://c9c5b090ffb3c6ff7c6cde98f8081306@o4507861625995264.ingest.de.sentry.io/4507883260149840',
};
