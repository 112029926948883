import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CognitoService } from './cognito.service';
import { TokenService } from './token.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router, private tokenService: TokenService,
    private cognitoService: CognitoService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const res = await this.cognitoService.currentSession();

    if (res && res?.tokens?.accessToken && res?.userSub) {
      // Check if token exists && user session also exists
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
