import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Keys } from '../utils/config/keys';

@Injectable()
export class TokenService {
  private token: string | null = null;

  constructor(private router: Router, private cookieService: CookieService) {
    this.checkTokenValidity();
  }

  setToken(token: string) {
    this.cookieService.set('token', token, { expires: 1, sameSite: 'Lax' });
  }

  getToken() {
    const token1 = localStorage.getItem(Keys.ID_TOKEN);
    // console.log(token1);
    return token1;
  }

  clearToken() {
    this.cookieService.delete('token');
  }

  checkTokenValidity(): void {
    // setInterval(
    //   () => {
    //     const token = this.getToken();
    //     if (!token) {
    //       if (this.router.url.indexOf('dash') >= 1) this.router.navigate(['/login']);
    //     }
    //     // TODO: Add logic to validate the token and redirect if invalid
    //   },
    //   5 * 60 * 1000,
    // ); // Check every 5 minutes
  }
}
