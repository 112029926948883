export function calculateTime(dateString: string) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMillis = currentDate.getTime() - givenDate.getTime();

  // Convert the difference to minutes
  const totalMinutes = Math.floor(differenceInMillis / 60000); // 1 minute = 60000 milliseconds

  // Define time units in minutes
  const minutesInHour = 60;
  const minutesInDay = minutesInHour * 24;
  const minutesInWeek = minutesInDay * 7;
  const minutesInMonth = minutesInDay * 30; // Approximation of a month as 30 days
  const minutesInYear = minutesInDay * 365; // Approximation of a year as 365 days

  // Calculate the time units
  const years = Math.floor(totalMinutes / minutesInYear);
  const months = Math.floor((totalMinutes % minutesInYear) / minutesInMonth);
  const weeks = Math.floor((totalMinutes % minutesInMonth) / minutesInWeek);
  const days = Math.floor((totalMinutes % minutesInWeek) / minutesInDay);
  const hours = Math.floor((totalMinutes % minutesInDay) / minutesInHour);
  const minutes = totalMinutes % minutesInHour;

  // Determine what to display
  if (totalMinutes < minutesInHour) {
    return `${minutes} min`;
  } else if (totalMinutes < minutesInDay) {
    return `${hours} hr`;
  } else if (totalMinutes < minutesInWeek) {
    return `${days} day`;
  } else if (totalMinutes < minutesInMonth) {
    return `${weeks} week`;
  } else if (totalMinutes < minutesInYear) {
    return `${months} month`;
  } else {
    return `${years} year`;
  }
}
