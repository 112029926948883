<!-- Main layout container -->
<nz-layout>
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="10">
      <nz-layout>
        <nz-content>
          <!-- Header section -->
          <nz-header>
            <!-- Logo and title -->
            <div class="auth-logo">
              <img src="/assets/Logo.svg" alt="Logo Image" />
              <div class="auth-logo-text">Builtdesign</div>
            </div>
          </nz-header>
          <!-- Child routes -->
          <router-outlet></router-outlet>
        </nz-content>
        <!-- Footer section : with copyright and help email -->
        <nz-footer>
          <div nz-row nzJustify="space-between">
            <div nz-col>&copy; builtdesign</div>
            <div nz-col>
              <span nz-icon nzType="mail" nzTheme="outline"></span>
              info@builtdesign.in
            </div>
          </div>
        </nz-footer>
      </nz-layout>
    </div>
    <!-- Column for quote box -->
    <div nz-col nzXs="0" nzSm="0" nzMd="14" nz class="quote-box">
      <h1>
        "Your home should tell the story of who you are and be a collection of what you love. Begin your journey to
        self-expression today."
      </h1>
      <div nz-row nzJustify="space-between">
        <div nz-col>
          <div class="author-name"> — Nate Berkus</div>
          <!-- <span class="author-designation">Founder, Layers.io</span> -->
        </div>
        <div nz-col>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
        </div>
      </div>
    </div>
  </div>
</nz-layout>
