import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class JobService {
  private apiUrl = `${environment.serverUrl}`;
  constructor(private http: HttpClient) {}

  private getToken(): string {
    // Retrieve the token from local storage or any other storage
    return localStorage.getItem('idToken') || '';
  }
  //get all the jobs
  getJobs(params?: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    let httpParams = new HttpParams();
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    return this.http.get<any>(`${this.apiUrl}/jobs`, { headers, params: httpParams });
  }

  //get job using id
  getJobById(id: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.http.get<any>(`${this.apiUrl}/jobs/${id}`, { headers });
  }

  // create job servcie
  createJob(data: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    const job = this.http.post<any>(
      `${this.apiUrl}/jobs`,
      {
        ...data,
      },
      { headers },
    );
    return job;
  }

  //update job service
  updateJob(id: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    const job = this.http.patch<any>(`${this.apiUrl}/jobs/${id}`, { ...data }, { headers });
    return job;
  }

  getServices(id?: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    let params = new HttpParams();
    if (id) {
      params = params.set('parent_id', id);
    }
    const services = this.http.get<any>(`${this.apiUrl}/services`, { headers, params });
    return services;
  }

  inviteArchitects(id: string, body: any): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/jobs/invite-architect/${id}`, body);
  }

  postQuery(body: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/support`, body);
  }

  postFeedback(body: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/support/feedback`,body);
  }
}
