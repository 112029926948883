<nz-layout class="main">
  <nz-header class="header">
    <div class="logo" routerLink="/home">
      <img src="/assets/Logo.svg" alt="Logo Image" />
      <div class="logo-text">Builtdesign</div>
    </div>
    <ul nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
      <li
        nz-menu-item
        routerLink="/explore-architects"
        nz-popover
        nzPopoverContent="Explore the profiles of architects"
        nzPopoverPlacement="bottomRight"
        class="step3"
      >
        Explore Architects
      </li>
      <li
        nz-menu-item
        (click)="openFeedbackModal()"
        [nzSelected]="isSelected"
        nz-popover
        nzPopoverContent="Please share feedback on how we can improve."
        nzPopoverPlacement="bottomRight"
        class="step4"
      >
        <span nz-icon nzType="like" nzTheme="outline" class="navbar-icons"></span>
      </li>
      <!-- <li nz-menu-item><span nz-icon nzType="bell" nzTheme="outline" class="navbar-icons"></span></li> -->
      <li
        nz-menu-item
        nz-popover
        [nzPopoverContent]="notificationTemplate"
        nzPopoverPlacement="bottomRight"
        [(nzPopoverVisible)]="isNotificationPanelVisible"
        class="step5"
      >
        <nz-badge [nzCount]="unreadCount" nzSize="small">
          <span nz-icon nzType="bell" nzTheme="outline" class="navbar-icons"></span>
        </nz-badge>
      </li>

      <!-- Notification Template -->
      <ng-template #notificationTemplate>
        <div class="notification-panel">
          <div class="notifcation-header">
            <span>Notifications</span>
            <a nz-button nzType="link" [disabled]="!unreadCount" (click)="markAllAsRead()">Mark all as read</a>
          </div>
          <nz-list [nzDataSource]="notifications" [nzRenderItem]="item">
            <ng-template #item let-notification>
              <nz-list-item
                *ngIf="notification?.action?.type == 'navigate'"
                [class]="notification?.is_read ? ' list-item readed' : 'list-item'"
                [routerLink]="'/job-dashboard/' + notification?.action?.payload"
                (click)="markAsRead(notification?._id)"
              >
                <div>
                  <nz-list-item-meta [nzTitle]="notification.description + ' (' + notification?.action?.payload + ')'">
                  </nz-list-item-meta>
                </div>
                <span class="time">{{ calculateTime(notification.createdAt) }} ago</span>
              </nz-list-item>
              <nz-list-item
                *ngIf="notification?.action?.type == 'default'"
                [class]="notification?.is_read ? ' list-item readed' : 'list-item'"
                (click)="markAsRead(notification?._id)"
              >
                <div>
                  <nz-list-item-meta [nzTitle]="notification.description"> </nz-list-item-meta>
                </div>
                <span class="time">{{ calculateTime(notification.createdAt) }} ago</span>
              </nz-list-item>
            </ng-template>
          </nz-list>
          <!-- <a *ngIf="showViewAll" class="view-all" (click)="viewAllNotifications()">View all notifications</a> -->
        </div>
      </ng-template>
      <li nz-submenu nzPlacement="bottomRight" [nzTitle]="profileTitle">
        <ul>
          <!-- <li nz-menu-group nzTitle="Item 1">
            <ul>
              <li nz-menu-item>Option 1</li>
              <li nz-menu-item>Option 2</li>
            </ul>
          </li>
          <li nz-menu-group nzTitle="Item 2"> -->
          <ul>
            <!-- <li nz-menu-item>Option 3</li>
              <li nz-menu-item>Option 4</li>
              <li nz-submenu nzTitle="Sub Menu">
                <ul>
                  <li nz-menu-item nzDisabled>Option 5</li>
                  <li nz-menu-item>Option 6</li>
                </ul>
              </li>
              <li nz-submenu nzDisabled nzTitle="Disabled Sub Menu">
                <ul>
                  <li nz-menu-item>Option 5</li>
                  <li nz-menu-item>Option 6</li>
                </ul>
              </li> -->
            <!-- <li nz-menu-item [nzMatchRouterExact]="true" [routerLink]="['/profile/edit-profile']">Edit Profile</li> -->
            <li nz-menu-item (click)="signOut()">Sign Out</li>
          </ul>
          <!-- </li> -->
        </ul>
      </li>
      <ng-template #profileTitle class="profile-title-div">
        <!-- <div > -->
        <nz-avatar nzIcon="user" nzSrc="/assets/img/default-avatar.png" class="profile-avtar"></nz-avatar>
        <span nz-icon nzType="down" class="profile-submenu-icon"></span>
        <!-- </div> -->
      </ng-template>
    </ul>
  </nz-header>
  <nz-layout class="inner-layout">
    <div class="support">
      <div (click)="handleModalOpen()" nz-tooltip nzTooltipTitle="Reach out to us for support." class="step6">
        <img src="assets/support.png" alt="support-icon" />
      </div>
      <nz-modal
        [(nzVisible)]="isModalOpen"
        (nzOnCancel)="handleModalClose()"
        [nzFooter]="null"
        nzTitle="Reach out to us for support"
        nzWidth="400px"
      >
        <div *nzModalContent="">
          <div class="support-modal">
            <form nz-form nzLayout="vertical" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <nz-form-item>
                <nz-form-label nzRequired>Type</nz-form-label>
                <nz-form-control>
                  <nz-select nzPlaceHolder="Select the type of issue" [(ngModel)]="issueType" formControlName="type">
                    <nz-option nzValue="Job Related" nzLabel="Job Related"></nz-option>
                    <nz-option nzValue="Account Related" nzLabel="Account Related"></nz-option>
                    <nz-option nzValue="Payment Related" nzLabel="Payment Related"></nz-option>
                    <nz-option nzValue="Others" nzLabel="Others"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <!-- <nz-form-item>
                  <nz-form-label nzRequired>Phone/Email</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="contact" placeholder="Enter your phone or email" />
                  </nz-form-control>
                </nz-form-item> -->

              <nz-form-item>
                <nz-form-label nzRequired>Message</nz-form-label>
                <nz-form-control>
                  <textarea rows="4" nz-input formControlName="description" placeholder="Enter your message"></textarea>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control>
                  <button
                    (click)="makeQuery()"
                    nz-button
                    nzShape="round"
                    nzType="primary"
                    [disabled]="!contactForm.valid"
                  >
                    Submit
                  </button>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>
      </nz-modal>
      <nz-modal
        [(nzVisible)]="isFeedbackModalOpen"
        (nzOnCancel)="handleFeedbackModalClose()"
        [nzFooter]="null"
        nzTitle="Provide Feedback"
        nzWidth="400px"
      >
        <div *nzModalContent="" class="feedbackModal">
          <form nz-form nzLayout="vertical" [formGroup]="feedbackForm">
            <nz-form-item>
              <nz-form-label>Enter your Email</nz-form-label>
              <nz-form-control>
                <input nz-input type="email" required formControlName="email" placeholder="Enter your email" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>What do you think about this platform? What can be improved?</nz-form-label>
              <nz-form-control>
                <textarea
                  rows="4"
                  nz-input
                  required
                  formControlName="feedback_text"
                  placeholder="Enter your valuable feedback"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
            <button
              nz-button
              nzShape="round"
              nzType="primary"
              (click)="postFeedback()"
              [disabled]="!feedbackForm.valid"
            >
              Submit
            </button>
          </form>
        </div>
      </nz-modal>
    </div>
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>
